
.home-h2{
    font-size: 34px;
    text-align: center;
    margin-bottom: 12px;
    line-height: 1.35;
    font-weight: 700;

    @media (max-width: 767px){
        font-size: 24px;
        margin-bottom: 6px;
    }
}
.theme-5-header{
    max-width: 100%;
    position: relative;
    padding-left: 13.75vw;
    background-color: #fcfcfd;

    @media (max-width: 1280px){
        padding-left: 70px;
    }
    @media (max-width: 767px){
        padding: 10vw 0;
        background-color: #0e172a;
        position: relative;
    }
}
.theme-5-header-text{
    width: 41%;
    max-width: 570px;
    padding: 12px 1.6em 12px 12px;
    margin-right: auto;

    @media (max-width: 991px){
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        padding: 0 20px;
        position: relative;
        z-index: 1;
        color: #fff;
        margin: 0 auto;
    }
    > .btn{
        font-size: 17px;

        @media (min-width: 1500px){
            font-size: 19px;
        }
        @media (max-width: 991px){
            font-size: 16px;
        }
    }
    h1{
        font-weight: bold;
        font-size: 2.5em;
        line-height: 1.4;
        margin-bottom: 2vw;

        @media (max-width: 1280px){
            font-size: 2em;
        }
        @media (max-width: 991px){
            font-size: 26px;
            line-height: 1.2;
        }
        @media (max-width: 767px){
            font-size: 28px;
            margin-bottom: 0.7em;
        }
        b{
            display: block;
            min-height: 22px;
        }
    }
    > p{
        margin-bottom: 3em;
        line-height: 1.35;
        font-size: 18px;

        @media (max-width: 1280px){
            margin-bottom: 2em;
        }
        @media (max-width: 991px){
            font-size: 16px;
            margin-bottom: 1.5em;
        }
        @media (min-width: 1500px){
            font-size: 20px;
        }
    }
}
.theme-5-header-img{
    width: 58.6%;

    @media (max-width: 991px){
        width: 50%;
    }
    @media (max-width: 767px){
        width: 100%;
        position: absolute;
        z-index: 0;
        opacity: .5;
        top: 0;
        background-color: #fff;
        height: 100%;
        left: 0;
    }
    > img{
        width: 100%;
        object-fit: cover;

        @media (max-width: 767px){
            height: 100%;
        }
    }
}
.theme-5-head-bg-2{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 4.38%;
}
.theme-5-section-2{
    background: #fcfcfd;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #f1f0f5 20%, rgba(240, 239, 244, 0.23) 100%);
    padding: 60px 0 36px;

    @media (max-width: 767px){
        padding: 48px 0 12px;
    }
}
.theme-5-section-2-container{
    margin: 0 -24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    align-items: flex-start;
    padding: 24px 0;

    @media (max-width: 767px){
        justify-content: center;
    }
}
.theme-5-section-2-item{
    padding: 48px 24px 48px;
    position: relative;
    text-align: center;
    width: 33.33%;
    max-width: 260px;

    @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
    }
    &:after{
        content: "";
        background: #ffffff;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        z-index: -1;
        transition: all .3s;
        border-radius: 172px 0 172px 172px;

        @media (max-width: 767px){
            opacity: 1;
            height: 100%;
        }
    }
    &:hover{

        &:after{
            opacity: 1;
            height: 100%;
        }
    }

    > img{
        height: 144px;
        margin-bottom: 20px;
        object-fit: contain;
    }
    > h3{
        font-size: 18px;
        margin-bottom: 48px;
        line-height: 1.6;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media (max-width: 1159px){
            font-size: 16px;
        }
        b{
            display: block;
            min-height: 22px;
        }
    }
}
.theme-5-section-3{
    padding: 60px 0 36px;

    @media (max-width: 767px){
        padding: 48px 0 24px;
    }
}
.theme-5-section-4{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: clamp(12px, 1.13vw, 18px);

    @media (max-width: 767px){
        flex-direction: column-reverse;
        font-size: 16px;
    }
}
.theme-5-section-4-img{
    width: 49.5%;
    object-fit: cover;
    border-radius: 0 0 19.5vw;

    @media (max-width: 767px){
        width: 100%;
        border-radius: 0 0 0 19.5vw;
    }
}
.theme-5-section-4-text{
    background-color: #253860;
    color: #ffffff;
    padding: 5vw 4.7vw;
    width: 49.5%;
    border-radius: 0 19.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 767px){
        width: 100%;
        padding: 40px 24px;
        border-radius: 0 19.5vw 0 0;
    }
    > h2{
        font-size: 1.945em;
        margin-bottom: 1.05em;

        @media (max-width: 767px){
            font-size: 24px;
            margin-bottom: 15px;
        }
    }
    > p{
        max-width: 33em;
        line-height: 1.6;
        font-size: 1em;

        @media (max-width: 767px){
            font-size: 15px;
        }
    }
    .btn{
        font-size: 1em;
        margin-top: 3.2vw;
    }
}
.theme-5-section-5{
    padding: 60px 0 36px;

    @media (max-width: 767px){
        padding: 48px 0 0;
    }
}
.theme-5-section-5-container{
    margin: 0 -12px;
    padding: 12px 0;
    justify-content: space-between;
}
.theme-5-section-5-item{
    width: 33.33%;
    padding: 12px;
    text-align: center;
    max-width: 391px;

    @media (max-width: 767px){
        width: 100%;
        margin: 0 auto 24px;
    }
    > img{
        margin-bottom: 21px;
        border-radius: 95px 0 95px 95px;
        width: 100%;
        height: 31vw;
        max-height: 384px;
        object-fit: cover;

        @media (max-width: 767px){
            height: 91vw;
            margin-bottom: 17px;
        }
    }
    > h3{
        font-size: 20px;
        margin-bottom: 5px;

        @media (max-width: 991px){
            font-size: 18px;
        }
        b{
            display: block;
            min-height: 22px;
        }
    }
    > p{
        font-size: 16px;
        margin-bottom: 0;

        @media (max-width: 991px){
            font-size: 15px;
        }
    }
}
.theme-5-section-6{
    padding: 60px 0 48px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f1f0f5 20%, rgba(240, 239, 244, 0.23) 100%);

    @media (max-width: 767px){
        padding: 48px 0 12px;
    }
}
.theme-5-section-6-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -12px;
    padding: 12px 0;
}
.theme-5-section-6-item{
    width: 33.33%;
    padding: 12px;
    max-width: 456px;

    @media (max-width: 991px){
        text-align: center;
    }
    @media (max-width: 767px){
        width: 100%;
        margin: 0 auto 30px;
    }
    > p{
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 0;

        @media (max-width: 991px) {
            font-size: 15px;
        }
    }
}
.theme-5-section-6-item-user{
    display: flex;
    align-items: center;
    margin-bottom: 26px;

    @media (max-width: 991px){
        flex-direction: column;
        align-items: flex-start;
    }
    > img{
        width: 85px;
        height: 85px;
        border-radius: 50%;
        object-fit: cover;
        border: solid 1px #ffffff;
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);

        @media (max-width: 991px){
            margin: 0 auto;
        }
    }
}
.theme-5-section-6-item-user-name{
    width: calc(100% - 85px);
    padding-left: 20px;

    @media (max-width: 991px){
        width: 100%;
        padding-left: 0;
    }
    > h3{
        font-size: 20px;

        @media (max-width: 991px){
            font-size: 17px;
            margin-top: 10px;
        }
        b{
            display: block;
            min-height: 22px;
        }
    }
    >  h4{
        font-size: 16px;
        margin-bottom: 0;
    }
}
