@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-300.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-regular.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-500.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-600.woff2') format('woff2');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
    url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/poppins-v20-latin-700.woff2') format('woff2');
}

@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 300;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-300.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 400;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-regular.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 500;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-500.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 600;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-600.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 700;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-700.woff2') format("woff2");
}

body{
    font-family: Poppins, NotoSansArmenian, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@import 'home';
@import '../../global/tenants-global';


:root {
    --nav-background: #ffffff;
    --nav-link: #000000;
    --nav-link-hover: #04CF9C;


    --btn1-background-color: #04CF9C;
    --btn1-background-hover-color: #04b588;
    --btn1-text-color: #ffffff;
    --btn1-text-hover-color: #ffffff;

    --btn1-outline-background-color: #ffffff;
    --btn1-outline-background-hover-color: #04CF9C;
    --btn1-outline-text-color: #000000;
    --btn1-outline-text-color-hover: #ffffff;
    --btn1-outline-border-color: #04CF9C;
    --btn1-outline-border-hover-color: #04CF9C;


    --btn2-background-color: #253860;
    --btn2-background-hover-color: #1b2844;
    --btn2-text-color: #ffffff;
    --btn2-text-hover-color: #ffffff;

    --btn2-outline-background-color: #ffffff;
    --btn2-outline-background-hover-color: #253860;
    --btn2-outline-text-color: #253860;
    --btn2-outline-text-color-hover: #ffffff;
    --btn2-outline-border-color: #253860;
    --btn2-outline-border-hover-color: #253860;


    --color-1: #04CF9C;
    --color-2: #253860;


    --course-background: #ffffff;
    --course-background-hover: #253860;
    --course-color: #1D1D1D;
    --course-color-hover: #ffffff;
    --course-color-hr: #e4e8f2;
    --course-color-hr-hover: #253860;
    --course-border: transparent;
    --course-radius: 0;
}
h1,h2,h3,h4,h5,h6, p{
    min-height: 17px;
}

.btn{
    border-radius: 2em;
}
.landing-container-full-text > h2{
    font-weight: 700;
}

.theme-5-info-section{
    padding: 35px 12px;

    @media (max-width: 767px){
        padding: 25px 12px;
    }
    .theme-all-info-section-2-container{
        padding: 35px 12px;

        @media (max-width: 767px){
            padding: 25px 12px;
        }
    }
}
